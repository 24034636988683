<template>
  <div class="indexStyle">
        <div class="head_img"  :style="`height:${banner_height}px`"></div>
        <div class="spot">
            <div class="h2">行业痛点</div>
            <ul>
                <li v-for="(item,index) in spotList" :key="index">
                    <img :src="item.img" alt="">
                    <div class="text">
                        <p>{{item.title}}</p>
                        <p>{{item.content}}</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="domain">
            <div class="h2" v-show="domainShow">我们能提供的系统开发领域</div>
            <div class="content" v-show="domainShow">覆盖全互联网行业的行业系统采购+定制系统开发</div>
            <ul v-show="domainShow">
                <li v-for="(item,index) in domainList" :key="index"  :style="`background: url('${item.img}') no-repeat`">
                    <div v-if="index !== domainList.length - 1">
                        <p>{{item.title}}</p>
                        <p>{{item.content}}</p>
                    </div>
                    <p v-else class="text">{{item.title}}</p>
                </li>
                <div class="list" v-for="item in (rowNum - domainList.length % rowNum)"><div v-if="domainList.length % rowNum > 0"></div></div>
            </ul>
        </div>
        <div class="flow">
            <div class="h2" v-show="flowShow">软件开发流程</div>
            <div class="img" v-show="flowShow"></div>
        </div>
        <correlationCase :caseShow="caseShow" :caseList="tourList" />
        <!-- <div class="consult">
            <div class="h2">立即微信扫码免费咨询</div>
            <div class="content">24小时在线咨询峰盛科技产品专家 欢迎免费获取制作项目方案</div>
            <ul>
                <li v-for="(item,index) in consultList" :key="index">
                    <p>扫码添加微信咨询</p>
                    <img :src="item.img" alt="">
                    <p>{{item.content1}}</p>
                    <p>{{item.content2}}</p>
                    <p>{{item.content3}}</p>
                </li>
            </ul>
        </div> -->
  </div>
</template>

<script>
import correlationCase from '@/components/correlationCase'
export default {
    components:{correlationCase},
    data(){
        return{
            spotList:[
                {
                    img:require('@/assets/image/customization/icon1.png'),
                    title:'开发外包市场太多，怎么选择？',
                    content:'选择峰盛科技。成熟完善的团队随时为您提供产品咨询、策划、方案、设计、开发、运维与推广的一站式服务',
                },
                {
                    img:require('@/assets/image/customization/icon2.png'),
                    title:'开发价格水太深，报价不透明？',
                    content:'在这里不用担心。因为我们有详细的开发需求明细表，每一项都有明确的收费标准，拒绝以客报价的情况发生，客户可根据所需功能选择开发服务，全透明公开',
                },
                {
                    img:require('@/assets/image/customization/icon3.png'),
                    title:'开发周期长，推三阻四？',
                    content:'在这里不存在。我们拥有成熟完善技术团队，每个技术人员从业7年以上的经验',
                },
                {
                    img:require('@/assets/image/customization/icon4.png'),
                    title:'前期响应快，后期服务跟不上？',
                    content:'在这里放心。后期服务，我们售后团队1V1,7*24小时快速响应，及时为您解决日常系统运维，修复项目BUG，运营营销咨询等'
                }

            ],
            domainList:[
                {
                    img:require('@/assets/image/customization/icon17.png'),
                    title:'电商类',
                    content:'多商家平台商城、单商家自营商城、直销分佣商城',
                },
                // {
                //     img:require('@/assets/image/customization/icon9.png'),
                //     title:'区块链类',
                //     content:'去中心化钱包、挖矿模式钱包、分销制度钱包、定制公链对接',
                // },
                {
                    img:require('@/assets/image/customization/icon10.png'),
                    title:'物联网类',
                    content:'共享设备类、智能家居类、智慧社区类、硬件控制类',
                },
                {
                    img:require('@/assets/image/customization/icon11.png'),
                    title:'社交类',
                    content:'IM社交类、语音连麦类、配对交友类、社区论坛类',
                },
                {
                    img:require('@/assets/image/customization/icon12.png'),
                    title:'政府类',
                    content:'大数据系统、定制类',
                },
                {
                    img:require('@/assets/image/customization/icon13.png'),
                    title:'直播类',
                    content:'直播购物类、会议公开课直播、教育直播类',
                },
                {
                    img:require('@/assets/image/customization/icon14.png'),
                    title:'定制类',
                    content:'APP定制、小程序定制、公众号定制、PC网站定制',
                },
                {
                    img:require('@/assets/image/customization/icon15.png'),
                    title:'技术入股',
                },
            ],
            consultList:[
                {
                    img:require('@/assets/image/customization/icon17.png'),
                    content1:'多商家平城',
                    content2:'1800000000',
                    content3:'多商家单商商佣商城',
                },
                {
                    img:require('@/assets/image/customization/icon9.png'),
                    content1:'去中心链对接',
                    content2:'1800000000',
                    content3:'城、直销商城',

                },
                {
                    img:require('@/assets/image/customization/icon10.png'),
                    content1:'共家居制类',
                    content2:'1800000000',
                    content3:'总经理 商务合作请联系',

                },
            ],
            //旅游
            tourList:[
                {
                    img:require('@/assets/image/case/5.png'),
                    title:'旅游系统',
                    content1:'行走二十岁',
                    content2:'为不同业态的旅游企业提供全方位的方案'
                },
                {
                    img:require('@/assets/image/case/6.png'),
                    title:'旅游系统',
                    content1:'启航旅游',
                    content2:'全媒体接入扩展性，满足客户多样化需求'
                },
                // {
                //     img:require('@/assets/image/case/7.png'),
                //     title:'旅游系统',
                //     content1:'企业建站开发服务',
                //     content2:'为各类型企业定制开发多功能显示官网'
                // },
                {
                    img:require('@/assets/image/case/8.png'),
                    title:'旅游系统',
                    content1:'乐翻天旅游',
                    content2:'提供服务旅游网管管理系统，三网合一'
                },
            ],
            rowNum:5,
            caseShow:false,
            domainShow:false,
            flowShow:false,
            windowWidth:document.documentElement.clientWidth,
            banner_height: document.documentElement.clientWidth > 1024 ?
            document.documentElement.clientWidth * 0.21 : document.documentElement.clientWidth * 0.29,
        }
    },
    mounted(){
        window.addEventListener('scroll', this.handleScroll, true);  // 监听（绑定）滚轮滚动事件
        window.onresize = () => {
            return (() => {
                window.fullWidth = document.documentElement.clientWidth
                this.windowWidth = window.fullWidth // 宽
                if(this.windowWidth >= 1024){
                    this.banner_height = this.windowWidth * 0.21
                }else{
                    this.banner_height = this.windowWidth * 0.29
                }
            })()
        }
        if(this.windowWidth <= 1024){
            this.domainShow = true
            this.flowShow = true
            this.caseShow = true
        } 
    },
    methods:{
         // 滚动监听 · 动画播放
        handleScroll(){ 
            if(this.windowWidth <= 1024)return
                // 页面滚动距顶部距离
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || 
                    document.body.scrollTop
            //动画播放设置
            let domain = document.getElementsByClassName('domain')
            let domain_h2 = domain[0].getElementsByClassName('h2')
            let domain_content = domain[0].getElementsByClassName('content')
            let domainLi = domain[0].getElementsByTagName('li')

            let flow = document.getElementsByClassName('flow')
            let flow_h2 = flow[0].getElementsByClassName('h2')
            let flow_img = flow[0].getElementsByClassName('img')
           //开发案例
            let Case = document.getElementsByClassName('case')
            let Case_h2 = Case[0].getElementsByClassName('h2')
            let Case_swiper = Case[0].getElementsByClassName('case-swiper-container')

            if(scrollTop > (domain[0].getBoundingClientRect().top + scrollTop) - domain[0].offsetHeight){
                this.domainShow = true
                domain_h2[0].classList.add('animated','fadeIn')
                domain_content[0].classList.add('animated','fadeIn')
                domainLi.forEach((item,index)=>{
                    if(index == 0 || index == 1 || index == 4 || index == 5){
                        item.classList.add('animated','fadeInLeft')
                    }else{
                        item.classList.add('animated','fadeInRight')
                    }
                })
            }
            if(scrollTop > (flow[0].getBoundingClientRect().top + scrollTop) - flow[0].offsetHeight / 1.5){
                this.flowShow = true
                flow_h2[0].classList.add('animated','fadeIn')
                flow_img[0].classList.add('animated','zoomIn')
            }
            if(scrollTop > (Case[0].getBoundingClientRect().top + scrollTop) - Case[0].offsetHeight / 1.5){
                this.caseShow = true
                Case_h2[0].classList.add('animated','fadeIn')
                Case_swiper[0].classList.add('animated','fadeInUp')
            }
            
        } 
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll, true);
    },
}
</script>

<style lang="less" scoped>
    .indexStyle {
        .head_img{
            background: url('~@/assets/image/customization/banner.png') no-repeat;
            background-size: 100%;
        }
        .spot{
            height: 594px;
            .h2{
                padding: 65px 0 26px 0;
            }
            ul{
                display: flex;
                flex-wrap: wrap;
                
                width: 1200px;
                margin: 0 auto;
                li{
                    background: url('~@/assets/image/customization/icon6.png') no-repeat center;
                    width: 600px;
                    height: 170px;
                    display: flex;
                    align-items: center;
                    &:hover{
                        background: url('~@/assets/image/customization/icon5.png') no-repeat center;
                    }
                    img{
                        margin: 0 45px;
                    }
                    .text{
                        width: 405px;
                        text-align: left;
                        p:nth-child(1){
                            font-size: 22px;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: #333333;
                        }
                        p:nth-child(2){
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            font-weight: 300;
                            color: #666666;
                            line-height: 20px;
                        }
                    }
                }

            }
        }
        .domain{
            height: 460px;
            background: url('~@/assets/image/customization/icon8.png') no-repeat center / 100%;
            
            .h2{
                color: #FFFFFF;
                padding: 65px 0 26px 0;
            }
            .content{
                color: #FFFFFF;
            }
            ul{
                width: 1200px;
                margin: 35px auto;
                display: flex;
                flex-wrap: wrap;
                li{
                    text-align: left;
                    width: 300px;
                    height: 150px;
                    padding: 25px 30px;
                    div{
                        p:nth-child(1){
                            font-size: 22px;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: #333333;
                            margin-bottom: 18px;
                        }
                        p:nth-child(2){
                            width: 195px;
                            height: 34px;
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            font-weight: 300;
                            color: #666666;
                            line-height: 20px;
                        }
                    }
                    .text{
                        line-height: 95px;
                        text-align: center;
                        font-size: 36px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #333333;
                    }
                }
                .list{
                    content: '';
                    width: 300px;
                    height: 150px;
                    border: 1px solid transparent;
                    padding: 5px;
                    overflow: hidden;
                }
            }
        }
        .flow{
            height: 730px;
             .h2{
                font-size: 36px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #333333;
                line-height: 81px;
                padding: 155px 0 40px 0;
            }
            .img{
                width: 1170px;
                margin: 0 auto;
                height: 370px;
                background: url('~@/assets/image/customization/icon7.png') no-repeat;
            }
        }
        .consult{
            height: 600px;
            background: url('~@/assets/image/customization/icon16.png') no-repeat;
            .h2{
                color: #FFFFFF;
                padding: 65px 0 26px 0;
            }
            .content{
                color: #FFFFFF;
            }
            ul{
                margin-top: 40px;
                display: flex;
                justify-content: center;
        
                li{
                    width: 250px;
                    height: 310px;
                    background: #FFFFFF;
                    margin-right: 80px;
                    img{
                        width: 160px;
                        height: 160px;
                    }
                    p:nth-child(1){
                        font-size: 12px;
                        font-family: Source Han Sans CN;
                        font-weight: 300;
                        color: #999999;
                        margin: 15px 0;
                    }
                    p:nth-child(3){
                        font-size: 16px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #333333;
                        line-height: 24px;
                        // margin-top: 6px
                    }
                    p:nth-child(4){
                        font-size: 16px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #333333;
                        line-height: 24px;
                    }
                    p:nth-child(5){
                        font-size: 12px;
                        font-family: Source Han Sans CN;
                        font-weight: 300;
                        color: #333333;
                        margin-top: 10px
                    }
                }
            }
        }
    }
@media screen and (max-width: 1024px) {
    .indexStyle {
        .head_img{
            height: 110px;
            background: url('~@/assets/image/customization/mobile_banner.png') no-repeat;
            background-size: 100%;
        }
        .spot{
            height: 440px;
            .h2{
                padding: 30px 0 12px 0;
            }
            ul{
                width: 100%;
                justify-content: center;
                li{
                    width: 357px;
                    height: 87px;
                    background-size: 357px 87px;
                    margin-bottom: 5px;
                    img{
                        max-width: 9%;
                        max-height: 39%;
                        margin: 0 20px;
                    }
                    .text{
                        width: 345px;
                        text-align: left;
                        p:nth-child(1){
                            font-size: 13px;
                        }
                        p:nth-child(2){
                            font-size: 9px;
                            line-height: 14px;
                            transform: scale(0.75);
                            margin-left: -40px;
                        }
                    }
                }
            }
        }
        .domain{
            height: 420px;
            background: url('~@/assets/image/customization/mobile_icon1.png') no-repeat center / 100%;
            .h2{
                padding: 25px 0 12px 0;
            }
            .content{
                margin-bottom: 15px;
            }
            ul{
                width: 100%;
                justify-content: center;
                margin: 0;
                li{
                    width: 180px;
                    height: 80px;
                    background-size: 180px 80px !important;
                    padding: 12px 18px;
                     div{
                        p:nth-child(1){
                            font-size: 13px;
                            margin-bottom: 0px;
                        }
                        p:nth-child(2){
                            font-size: 9px;
                            transform: scale(0.75);
                            margin-left: -25px;
                        }
                    }
                    .text{
                        line-height: 52px;
                        font-size: 17px;
                    }
                }
                .list{
                    width: 180px;
                    height: 80px;
                }
            }
        }
        .flow{
            height: 405px;
             .h2{
                font-size: 19px;
                padding: 25px 0 18px 0;
                line-height: inherit;
            }
            .img{
                 width: 325px;
                height: 315px;
                background: url('~@/assets/image/customization/mobile_icon2.png') no-repeat;
                background-size: 100%;
            }
        }
        .consult{
            height: 263px;
            background: url('~@/assets/image/customization/mobile_icon3.png') no-repeat;
            background-size: 100%;
            .h2{
                padding: 25px 0 5px 0;
            }
            ul{
                margin-top: 18px;
                li{
                    width: 108px;
                    height: 146px;
                    margin-right: 10px;
                    img{
                        width: 69px;
                        height: 69px;
                    }
                    p:nth-child(1){
                        font-size: 7px;
                        transform: scale(0.58);
                        margin: 5px 0;
                    }
                    p:nth-child(3){
                        font-size: 12px;
                        line-height: 15px;
                        margin-top: -5px

                    }
                    p:nth-child(4){
                        font-size: 12px;
                        line-height: 15px;
                    }
                    p:nth-child(5){
                        width: 140px;
                        font-size: 9px;
                        transform: scale(0.75);
                        margin-top: 0px;
                        margin-left: -15%;
                    
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1025px) {
    .indexStyle {
        margin-top: 90px;
    }

}
</style>